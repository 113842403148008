import React from "react";
import "./App.css";
import { Link } from "react-scroll";
import { Waypoint } from "react-waypoint";
import "animate.css/animate.min.css";
import { ReactComponent as TwitterIcon } from "./assets/twitter-icon.svg";
import { ReactComponent as LinkedInIcon } from "./assets/linkedin-icon.svg";

import { ReactComponent as FacebookIcon } from "./assets/facebook-icon.svg";
import { ReactComponent as InstagramIcon } from "./assets/instagram-icon.svg";

import logoCC from "./assets/logo_cc.png";
import Helmet from "react-helmet";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { Tooltip } from "react-tooltip";
import { Formik, Field, Form, ErrorMessage } from "formik";
import * as Yup from "yup";
import axios from 'axios';

import bgImage from "./assets/backgroundimage.jpg";
import servicesImage from "./assets/servicesImage.png";

import ContactsImage from "./assets/ContactsImage.jpg";

import Person1Image from "./assets/person1.jpg";
import Person2Image from "./assets/person2.jpg";
import Person3Image from "./assets/person3.jpg";
import Person4Image from "./assets/person4.jpg";
import Person5Image from "./assets/person5.jpg";
import Person6Image from "./assets/person6.jpg";

// import House1Image from "./assets/house1.jpg";
// import House2Image from "./assets/house2.jpg";
// import House3Image from "./assets/house3.jpg";

import { ReactComponent as Logo } from './assets/LOGO.svg';


// function Property({ property }) {
//   return (
//     <div className="property-card">
//       <div className="property-image">
//         <img src={property.image} alt={property.address} />
//         <div className="image-overlay">
//           <div className="overlay-top">{property.price}</div>
//           <div className="overlay-bottom">{property.address}</div>
//         </div>
//       </div>
//     </div>
//   );
// }

// function PropertiesSlider() {
//   const settings = {
//     dots: true,
//     infinite: false,
//     speed: 500,
//     slidesToShow: 1,
//     slidesToScroll: 1,
//     initialSlide: 0,
//     responsive: [
//       {
//         breakpoint: 1024,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//       {
//         breakpoint: 600,
//         settings: {
//           slidesToShow: 1,
//           slidesToScroll: 1,
//         },
//       },
//     ],
//   };

//   const properties = [
//     {
//       price: "$999,999",
//       address: "123 Main St, Anywhere, USA",
//       image: House1Image,
//     },
//     {
//       price: "$888,888",
//       address: "456 Oak St, Anywhere, USA",
//       image: House2Image,
//     },
//     {
//       price: "$777,777",
//       address: "789 Pine St, Anywhere, USA",
//       image: House3Image,
//     },
//     // Add more properties as needed
//   ];

//   return (
//     <div className="properties-container">
//       <Slider {...settings}>
//         {properties.map((property, index) => (
//           <Property key={index} property={property} />
//         ))}
//       </Slider>
//     </div>
//   );
// }

function Testimonial({ testimonial }) {
  return (
    <div className="testimonial-card">
      <div className="testimonial-image">
        <img src={testimonial.image} alt={testimonial.author} />
      </div>
      <p className="testimonial-text">{testimonial.text}</p>
      <p className="testimonial-author">- {testimonial.author}</p>
    </div>
  );
}

function Testimonials() {
  const settings = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 3,
    initialSlide: 0,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const testimonials = [
    {
      text: "Working with Matt was a seamless experience. He ensured I found a home that met all my criteria.",
      author: "Michael Richardson",
      image: Person1Image,
    },
    {
      text: "Onur's knowledge and negotiation skills helped me get an excellent deal on my new home. Couldn't have done it without him.",
      author: "Ebru Yilmaz",
      image: Person2Image,
    },
    {
      text: "The level of professionalism Matt showcases is commendable. He's my go-to for all real estate needs.",
      author: "David Grey",
      image: Person3Image,
    },
    {
      text: "Matt Onur went above and beyond to make sure I felt confident in my property choice. Can't thank him enough.",
      author: "Mehmet Öztürk",
      image: Person4Image,
    },
    {
      text: "With Matt Onur's guidance, the selling process felt effortless. His market insights were invaluable.",
      author: "Lucy Martin",
      image: Person5Image,
    },
    {
      text: "If you want a stress-free real estate experience, Matt Onur is the person to call. Thoroughly impressed with his service.",
      author: "Karen Finch",
      image: Person6Image,
    },
    // Add more testimonials as needed
  ];

  return (
    <div className="testimonials-container">
      <h2>Client Testimonials</h2>
      <Slider {...settings}>
        {testimonials.map((testimonial, index) => (
          <Testimonial key={index} testimonial={testimonial} />
        ))}
      </Slider>
    </div>
  );
}

function SubscriptionForm() {
  return (
    <Formik
      initialValues={{
        email: "",
      }}
      validationSchema={Yup.object({
        email: Yup.string().email("Invalid email address").required("Required"),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        axios.post('https://landingpageonurserverside.vercel.app/api/subscribe', values)
          .then(response => {
            if (response.data.status === 'success'){
              alert("Subscription Successful."); 
              setSubmitting(false);
              resetForm();
            } else if(response.data.status === 'fail'){
              alert("Subscription failed.")
            }
          })
          .catch(error => {
            console.error(error.response);
          });
      }}
    >
      <Form>
        <label htmlFor="email">Subscribe to our newsletter:</label>
        <Field name="email" type="email" />
        <ErrorMessage name="email" component="div" className="error" />

        <button type="submit">Subscribe</button>
      </Form>
    </Formik>
  );
}


function ContactForm() {
  return (
    <Formik
      initialValues={{
        name: "",
        email: "",
        message: "",
      }}
      validationSchema={Yup.object({
        name: Yup.string()
          .min(2, "Name must be at least 2 characters")
          .required("Required"),
        email: Yup.string().email("Invalid email address").required("Required"),
        message: Yup.string()
          .min(10, "Message must be at least 10 characters")
          .required("Required"),
      })}
      onSubmit={(values, { setSubmitting, resetForm }) => {
        axios.post('https://landingpageonurserverside.vercel.app/api/send', values)
          .then(response => {
            if (response.data.status === 'success'){
              alert("Message Sent."); 
              setSubmitting(false);
              resetForm();
            } else if(response.data.status === 'fail'){
              alert("Message failed to send.")
            }
          })
          .catch(error => {
            console.error(error.response);
          });
      }}
    >
      <Form>
        <label htmlFor="name">Name:</label>
        <Field name="name" type="text" />
        <ErrorMessage name="name" component="div" className="error" />

        <label htmlFor="email">Email:</label>
        <Field name="email" type="email" />
        <ErrorMessage name="email" component="div" className="error" />

        <label htmlFor="message">Message:</label>
        <Field name="message" as="textarea" className="message-textarea" />
        <ErrorMessage name="message" component="div" className="error" />

        <button type="submit">Submit</button>
      </Form>
    </Formik>
  );
}

function App() {
  return (
    <div className="App">
      <Helmet>
        <meta charSet="utf-8" />
        <title>Your trusted real-estate advisor</title>
        <meta
          name="description"
          content="Navigate the real estate market with confidence with our trusted and experienced real estate agents. We provide personalised advice and dedicated service."
        />
        <meta
          name="keywords"
          content="real estate, property, homes for sale, real estate agent, property advisor"
        />
        <meta property="og:type" content="website" />
        <meta property="og:site_name" content="Real Estate Agent" />
        <meta
          property="og:title"
          content="Real Estate Agent: Your trusted property advisor"
        />
        <meta
          property="og:description"
          content="Navigate the real estate market with confidence with our trusted and experienced real estate agents. We provide personalised advice and dedicated service."
        />
        <meta property="og:image" content={logoCC} />
        <meta property="og:url" content={window.location.href} />
        <link rel="canonical" href={window.location.href} />
      </Helmet>

      <header className="App-header">
        <Link to="contact" smooth={true} duration={500} className="logo">
          <div className="logo-text">
          <p><b>+1-832-729-3059</b> <span style={{marginLeft: '10px'}}></span> <b>| </b> <span style={{marginLeft: '10px'}}></span> <b> realty@mattonur.com</b></p>
          </div>
        </Link>

{/* <Link to="contact" smooth={true} duration={500} className="logo">
        <div className="logo-container">
            <Logo />
        </div>
    </Link> */}

        <nav className="nav-links">
          <Link to="about" smooth={true} duration={500}>
          <b>About</b>
          </Link>
          <Link to="services" smooth={true} duration={500}>
          <b>Services</b>
          </Link>
          {/* <Link to="properties" smooth={true} duration={500}>
            Featured Properties
          </Link> */}
          <Link to="testimonials" smooth={true} duration={500}>
          <b>Testimonals</b>
          </Link>


          
          <Link to="contact" smooth={true} duration={500}>
          <b>Contact</b>
          </Link>
        </nav>
      </header>

      <section className="App-about full-page" id="about">
        <Waypoint
          onEnter={() => {
            document.getElementById("about").classList.add("animate__fadeIn");
          }}
        >
          <div className="about-content">
            <div className="about-text">
              {/* <h4>Your Trusted Real Estate Advisor</h4>
              <h2>Matt Onur</h2> */}

<div className="logo-container">
              <Logo />
              </div>

              <p>
                An experienced real estate agent, dedicated to offering
                exceptional service. Matt's knowledge of the real estate market
                and commitment to client satisfaction make him your trusted
                property advisor.
              </p>
              <Link to="contact" smooth={true} duration={500}>
                <button className="contact-button">Contact Matt</button>
              </Link>
            </div>
            <div
              className="about-image"
              style={{ backgroundImage: `url(${bgImage})` }}
            >
              <div className="location-tag">Houston, Texas</div>
            </div>
          </div>
        </Waypoint>
      </section>

      <section className="services-section full-page" id="services">
        <Waypoint
          onEnter={() => {
            document
              .getElementById("services")
              .classList.add("animate__fadeIn");
          }}
        >
          <div className="services-content">
            <div className="services-image-container">
              <img
                src={servicesImage}
                alt="Services"
                className="responsive-image-servicesImage"
              />
            </div>
            <div className="services-text-container">
              <h2>Exquisite Services</h2>
              <p>
                Offering a diverse range of services tailored to cater unique
                needs. Beyond transactions, delivering an experience that is
                seamless and stress-free.
              </p>
              <ul>
                <li>Personalized Property Search</li>
                <li>Competitive Market Analysis</li>
                <li>Professional Property Marketing</li>
                <li>Negotiation & Contracting</li>
                <li>Comprehensive After Sale Support</li>
              </ul>
              <Link to="contact" smooth={true} duration={500}>
                <button className="services-contact-button">
                  Get in Touch
                </button>
              </Link>
            </div>
          </div>
        </Waypoint>
      </section>

      {/* <section className="App-properties full-page" id="properties">
        <Waypoint
          onEnter={() => {
            document
              .getElementById("properties")
              .classList.add("animate__fadeIn");
          }}
        >
          <div className="section-content">
            <div className="text-container">
              <h2>Featured Properties</h2>
              <p>
                Explore our handpicked selection of properties that offer the
                best in comfort, location, and value.
              </p>
            </div>
            <div className="property-content">
              <PropertiesSlider />
              </div>
          </div>
        </Waypoint>
      </section> */}



      <section className="App-testimonials full-page" id="testimonials">
        <Waypoint
          onEnter={() => {
            document
              .getElementById("testimonials")
              .classList.add("animate__fadeIn");
          }}
        >
          <div className="section-content">
            <Testimonials />
          </div>
        </Waypoint>
      </section>
      <section className="App-contact full-page" id="contact">
        <Waypoint
          onEnter={() => {
            document.getElementById("contact").classList.add("animate__fadeIn");
          }}
        >
          <div className="contact-content">
            <div className="image-container">
              <img
                src={ContactsImage}
                alt="ContactsImage"
                className="responsive-image-ContactsImage"
              />
            </div>

            <div className="contact-form-container">
              <div className="ContactForm">
                <h2>Contact Us</h2>
                <ContactForm />
              </div>
              <div className="SubscriptionForm">
                <SubscriptionForm />
              </div>
            </div>
          </div>
        </Waypoint>
      </section>

      <footer>
        <div className="footer-columns">
          {/* Skipped for brevity */}

          <div className="footer-column">
            <h4>About Matt Onur</h4>
            <p>
              Experience the difference with a real estate professional who is
              passionate about helping you find your dream home. As a dedicated
              and experienced agent, I understand the value of personalized
              service and attention to detail. With a deep knowledge of the
              local market and a commitment to delivering exceptional results, I
              am here to guide you through every step of your real estate
              journey.
            </p>
          </div>
          <div className="footer-column">
            <h4>Quick Links</h4>
            <ul>
              <li>
                <a
                  href="mailto:realty@mattonur.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Contact Us
                </a>
              </li>
            </ul>
          </div>
          <div className="footer-column">
            <h4>Follow Us</h4>
            <div className="social-icons">
              <a
                href="https://twitter.com/mattonur"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Twitter"
                data-tip="Follow us on Twitter"
              >
                <TwitterIcon alt="Twitter" />
              </a>
              <Tooltip place="bottom" type="dark" effect="solid" />

              <a
                href="https://www.linkedin.com/company/mattonur"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="LinkedIn"
                data-tip="Follow us on LinkedIn"
              >
                <LinkedInIcon alt="LinkedIn" />
              </a>
              <Tooltip place="bottom" type="dark" effect="solid" />

              <a
                href="https://www.facebook.com/mattonur"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Facebook"
                data-tip="Follow us on Facebook"
              >
                <FacebookIcon alt="Facebook" />
              </a>
              <Tooltip place="bottom" type="dark" effect="solid" />

              <a
                href="https://www.instagram.com/mattonur"
                target="_blank"
                rel="noopener noreferrer"
                aria-label="Instagram"
                data-tip="Follow us on Instagram"
              >
                <InstagramIcon alt="Instagram" />
              </a>
              <Tooltip place="bottom" type="dark" effect="solid" />

              {/* Add more social media icons here */}
            </div>
          </div>
        </div>
        <div className="footer-bottom">
          <span>
            © {new Date().getFullYear()} Matt Onur. All rights reserved.
          </span>
        </div>
      </footer>
    </div>
  );
}

export default App;
